.featured {
    margin: 30px 0;
  }
  
  .featured h1 {
    margin-bottom: 25px;
    text-align: center;
  }
  
  .featured__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }