.nav {
    height: 80px;
    background-color: #252525;
    color: white;
    box-shadow: 0px 5px 20px -10px rgba(66, 68, 90, 1);
  }
  
  .nav .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 480px) {
    .nav .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  
  .nav a {
    font-size: 130%;
    font-weight: 500;
    text-decoration: none;
    color: white;
  }
  
  .nav__logo {
    width: 250px;
  }       

  .nav__search {
    display: flex;
  }

  .nav__search input {
    font-family: 'MuseoModerno', cursive;
    font-weight: 300;
    height: 30px;
    border: none;
    border-radius: 20px;
    padding-left: 10px ;
    padding-right: 35px;
    width: 100%;
  }

  .nav__search input:focus {
    outline: none;
  }
  
  .nav__search button {
    height: 32px;
    border-radius: 20px;
    background: #5E5DF0;
    color: white;
    border: none;
    margin-left: -30px;
    padding: 0 10px;
  }

  .nav__search button:hover {
    cursor: pointer;
  }
  